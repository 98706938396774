<template>
  <div>
    <h3>Report Management</h3>
    <p>
      Select a report to be run. Most reports allow for a date range to be
      provided.
    </p>
    <b-table
      striped
      :fields="fields"
      :items="items"
      small
      bordered
      sort-icon-left
    >
      <template v-slot:cell(name)="data">
        <a class="text-primary" @click="getLinkDest(data)">
          {{ data.item.name }}
          <span class="error" v-if="data.item.reportingServiceReady">*</span>
        </a>
      </template>
    </b-table>
    <p class="mt-2">
      <span style="color: red;">*</span> This report is now available for
      automated delivery. To receive these reports every night, please contact
      park manager to add your email to “Reporting Email” in the park
      configuration.
    </p>
  </div>
</template>

<script>
export default {
  name: "AdminReportList",
  title: "Admin - Report Management",
  data() {
    return {
      fields: [
        {
          key: "name",
          sortable: true,
          label: "Reports"
        },
        {
          key: "description",
          sortable: false,
          label: "Description"
        }
      ],
      items: [
        {
          id: 10,
          name: "Admin Override Report",
          reportingServiceReady: false,
          description:
            "The Admin Override Report is at the park level and provides details on any reservation override that was performed by an admin user on a reservation."
        },
        {
          id: 1,
          name: "Camper Report",
          reportingServiceReady: true,
          description:
            "The Camper Report is at the park level and provides reservation details broken down by arriving, on-site, and departing campers for the date specified."
        },
        {
          id: 2,
          name: "Cancelled Reservations Report",
          reportingServiceReady: true,
          description:
            "The Cancelled Reservations Report is at the park level and provides details for reservations that have been cancelled matching the date range specified."
        },
        {
          id: 3,
          name: "Closure Report",
          reportingServiceReady: false,
          description:
            "The Park Closure Report is at the park level and provides a listing of the reservations along with the reservation details for those that have been affected by a closures action for the date range specified."
        },
        // {
        //   id: 14,
        //   name: "Disbursement Report",
        //   reportingServiceReady: false,
        //   description:
        //     "Generates break up of the TPE disbursement amounts for a date range aggregated to accounting codes for each park."
        // },
        {
          id: 4,
          name: "Facility Changeover Report",
          reportingServiceReady: true,
          description:
            "The Facility Changeover Report is at the park level and provides a visual representation of all spots in the selected park along with that spot’s inventory status for the date period being reported."
        },
        {
          id: 13,
          name: "Field Deposit Report",
          reportingServiceReady: false,
          description:
            "Report the details of amounts of sale done in parks for date range."
        },
        {
          id: 11,
          name: "Generate Site Markers",
          reportingServiceReady: true,
          description:
            "Site Markers are generated at the park level for the arrival date specified and include a page of information relating to each reservation scheduled for that arrival date."
        },
        {
          id: 15,
          name: "Inventory Amenity Detail",
          reportingServiceReady: false,
          description:
            "The Inventory Amenity Detail report is at the park level and provides list of all park inventory and attribute information."
        },
        {
          id: 5,
          name: "Maintenance Report",
          reportingServiceReady: false,
          description:
            "The Maintenance Report is at the park level and provides individual reservation details for each reservation categorized by product class for the date range specified."
        },
        {
          id: 6,
          name: "Occupancy By Product Class Report",
          reportingServiceReady: false,
          description:
            "The Occupancy By Product Class Report is at the park level and provides occupancy numbers and occupancy percentages for each product class within the park for the date range specified."
        },
        {
          id: 7,
          name: "Occupancy By Revenue Report",
          reportingServiceReady: false,
          description:
            "The Occupancy By Revenue Report is at the park level and provides reservation/reservation night numbers along with total revenue for each product class within the park for the date range specified."
        },
        {
          id: 8,
          name: "Park Visitation By Residency / Discount Class Report",
          reportingServiceReady: false,
          description:
            "The Park Visitation by Residency/Discount Class is at the park level and provides reservation figures broken down by residency and discount class for the date range specified."
        },
        {
          id: 9,
          name: "Reservation Method Report",
          reportingServiceReady: false,
          description:
            "The Reservation Methods Report is at the park level and provides reservation figures for that park broken down by sales channel for the date range specified."
        },
        {
          id: 12,
          name: "Revenue Break up Report",
          reportingServiceReady: false,
          description:
            "Accounting code code break up of all revenue collected for sale of park reservations."
        }
      ]
    };
  },
  methods: {
    getLinkDest(data) {
      if (data.item.id == 1) {
        this.$router.push("/admin/reporting/camper").catch(() => {});
      } else if (data.item.id == 2) {
        this.$router
          .push("/admin/reporting/cancelledreservations")
          .catch(() => {});
      } else if (data.item.id == 3) {
        this.$router.push("/admin/reporting/closure").catch(() => {});
      } else if (data.item.id == 4) {
        this.$router
          .push("/admin/reporting/facilitychangeover")
          .catch(() => {});
      } else if (data.item.id == 5) {
        this.$router.push("/admin/reporting/maintenance").catch(() => {});
      } else if (data.item.id == 6) {
        this.$router
          .push("/admin/reporting/occupancybyproductclass")
          .catch(() => {});
      } else if (data.item.id == 7) {
        this.$router
          .push("/admin/reporting/occupancybyrevenue")
          .catch(() => {});
      } else if (data.item.id == 8) {
        this.$router.push("/admin/reporting/parkvisitation").catch(() => {});
      } else if (data.item.id == 9) {
        this.$router.push("/admin/reporting/reservationmethod").catch(() => {});
      } else if (data.item.id == 10) {
        this.$router.push("/admin/reporting/adminoverride").catch(() => {});
      } else if (data.item.id == 11) {
        this.$router.push("/admin/reporting/sitemarker").catch(() => {});
      } else if (data.item.id == 12) {
        this.$router.push("/admin/reporting/revenuebreakup").catch(() => {});
      } else if (data.item.id == 13) {
        this.$router.push("/admin/reporting/fielddeposit").catch(() => {});
      } else if (data.item.id == 14) {
        this.$router.push("/admin/reporting/disbursement").catch(() => {});
      } else if (data.item.id == 15) {
        this.$router.push("/admin/reporting/amenities").catch(() => {});
      }
    }
  }
};
</script>

<style scoped>
.btn {
  border: none;
}
</style>
